import React from "react";
import ProjectItem from "../components/ProjectItem";
import { ProjectList } from "../helpers/ProjectList";
import "../styles/Projects.css";

const Projects = () => {
  return (
    <div className="projects">
      <h1>My Personal Projects</h1>
      <div className="projectList">
        {ProjectList.map((el, index) => (
          <ProjectItem key={index} id={index} name={el.name} image={el.image} />
        ))}
      </div>
    </div>
  );
};

export default Projects;
